import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import tikotak from "../../Assets/Projects/tikotak.png";
import corewar from "../../Assets/Projects/corewar.png";
import npuzzle from "../../Assets/Projects/npuzzle.png";
import rt from "../../Assets/Projects/rt.png";
import gptic from "../../Assets/Projects/gptic.png";
import eb_blog from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={npuzzle}
              isBlog={false}
              title="Youtube Scripter"
              description="A Chrome Extension to Chat with AI about anything related or non related to a given Youtbe Video."
              ghLink="https://github.com/raphaexl/ChatAPI"
              demoLink="https://chromewebstore.google.com/detail/youtube-scripter/nkenhionmhdegjkgghhigaifcmpioeff"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={rt}
              isBlog={false}
              title="RT"
              description="RT Realtime Raytracing."
              ghLink="https://github.com/raphaexl/RT"
              demoLink="https://github.com/raphaexl/RT"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={eb_blog}
              isBlog={false}
              title="Ebatchas"
              description="My personal blog page build with Django and Postgresql. Easy to write blogs using markdown."
              ghLink="https://github.com/raphaexl"
              demoLink="https://batch.blog/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={gptic}
              isBlog={false}
              title="Gptic"
              description="Mobile appplication. I made a Chat GPT mobile app. AI Assistant using Flutter Framework with some of it's amazing features"
              ghLink="https://play.google.com/store/apps/details?id=com.ebatchas.gptic"
              demoLink="https://play.google.com/store/apps/details?id=com.ebatchas.gptic"              
            />
          </Col>


          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={corewar}
              isBlog={false}
              title="Corewar"
              description="This project involves creating a virtual arena, and having simplistic-language programs fight each other. You will thus get acquainted with VM conception (with instructions it recognizes, registers, etc) and compilation problems of an assembly language in bytecode. In bonus, the pleasure to have your champions fight in the arena."
              ghLink="https://github.com/raphaexl/Corewar"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tikotak}
              isBlog={false}
              title="tikOtak"
              description="Talk directly to your new tikOtak contact via WhatsApp and get help with your research, answers to your questions and much more. Let me take care of your tasks and organization so you can focus on what really matters."
              ghLink="https://tikotak.io"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
